* {
    margin: 0;
    border: 0;
    box-sizing: border-box;
    font-family: Roboto, sans-serif;
}

body {
    background-color: #090909;
}

/* Desktop media query with a minimum width of 768px (adjust as needed) */
@media (min-width: 768px) {
  /* Apply styles to elements with class names .class1 and .class2 */
  .main-page,
  .main-events-container,
  #main-location-container {
    max-width: 400px; /* Set the maximum width as needed */
    margin: 0 auto;   /* Center the content horizontally */
    /* Add other styles as needed */
  }

  iframe {
    position: absolute;
    top: -70px;
    left: 50% - 250px;
    width: 400px;
    z-index: -5;
  }
}

#not-mobile {
    display: none;
}

/* @media only screen and (min-device-width: 480px) {

    #not-mobile {
        display: block;
    }

    #content {
        display: none;
    }

} */

#header {
  display: flex;
  align-items: top;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 5%;
  padding-right: 5%;
  gap: 0.7rem;
}

#logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.un-ordered-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-left: 5%;
    margin-right: 5%;
}

.button-link {
  display: flex;
  background-color: #151515;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

li {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
}

.card-container {
  display: flex;
  padding: 0px 15px;
  padding-bottom: 0px;
  text-decoration: none;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.card-left-outer {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  width: 70%;
}

.card-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
}

.score {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 400;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #383838;
  color: #151515;
}

.bar-name-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #CBCBCB;
}

.bar-name {
  font-size: 17px;
  font-weight: bold;
  color: #CBCBCB;
}

.bar-name-small {
  font-size: 16px;
  font-weight: bold;
  color: #CBCBCB;
}

.bar-addy {
  width: max-content;
  font-size: 12px;
  font-weight: 400;
  border-top: 1px solid #666666;
  padding-left: 2px;
  padding-right: 6px;
  padding-top: 2px;
  color: #666666;
}

.timestamp {
  font-size: 11px;
  font-weight: 400;
  color: #666666;
}

.card-right-stack {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  color: #CBCBCB;
}

#card-right-substack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trending-container {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color:#151515;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151515;
}

.trending-container.up {
  border: 1px solid #666666;
}

.trending-up {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: 500;
}

.trending-container.down {
  border: 1px solid #666666;
}

.trending-down {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: 500;
}

#graph-parent {
  display: flex;
  width: 85%;
  height: 23vh;
  border-bottom: 1px solid #4D4C4C;
  border-top: 1px solid #4D4C4C;
  margin-right: 20%;
  margin-left: 20%;
}


input[type="range"] {
    width: 300px;
    background: transparent;
  }
  /*###################################*/
  /*###########Responsive Range########*/
  /*###################################*/
  /*##############Core Style###########*/
  
  input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  
  input[type="range"]:focus {
    outline: none;
  }
  
  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  /*#############Thumb##############*/
  
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 30px;
    width: 15px;
    border-radius: 3px;
    background: #6B6B6B;
    cursor: pointer;
    margin-top: -12px;
  }
  
  input[type="range"]::-moz-range-thumb {
    height: 40px;
    width: 40px;
    background: #6B6B6B;
    cursor: pointer;
  }
  
  input[type="range"]::-ms-thumb {
    height: 40px;
    width: 40px;
    background: #6B6B6B;
    cursor: pointer;
  }
  /*#############Track##############*/
  
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #383838;
  }
  
  input[type="range"]:active::-webkit-slider-runnable-track {
    background: #383838;
  }
  
  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: #383838;
  }
  
  input[type="range"]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  input[type="range"]::-ms-fill-lower {
    background: #383838;
  }
  
  input[type="range"]:focus::-ms-fill-lower {
    background: #383838;
  }
  
  input[type="range"]::-ms-fill-upper {
    background: #383838;
  }
  
  input[type="range"]:focus::-ms-fill-upper {
    background: #383838;
  }

  #rating {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px;
    border-radius: 25px;
    background-color: #151515;
  }

  .slider {
    width:100%;
    padding:15px;
    padding-left: 0px;
    background:#fcfcfc;
    display:flex;
    align-items:center;
    border-radius: 25px;
    color: #EBEBEB;
    background-color: #151515;
   }

   .slider-container {
    display: flex;
    width: 100%;
    align-items: center;
    padding-left: 15px;
   }

   .sliderLabel {
    margin-right: 20px;
    margin-left: 0.5rem;
    font-size: 14px;
   }

   .slider span {
    font-size:18px;
    font-weight:600;
    padding-left:20px;
    color: #EBEBEB;
   }
   .slider input[type=”range”] {
    -webkit-appearance:none !important;
    width:420px;
    height:2px;
    background:#EBEBEB;
    border:none;
    outline:none;
   }
   .slider input[type=”range”]::-webkit-slider-thumb {
    -webkit-appearance:none !important;
    width:40px;
    height:40px;
    background:#fcfcfc;
    border:2px solid #EBEBEB;
    border-radius:50%;
    cursor:pointer;
   }
   .slider input[type=”range”]::-webkit-slider-thumb:hover {
    background:#EBEBEB;
   }


.switch-button {
  background: #383838;
  border-radius: 30px;
  overflow: hidden;
  width: 90%;
  text-align: center;
  font-size: 11px;
  letter-spacing: 1px;
  color: #EBEBEB;
  position: relative;
  padding-right: 45%;
  position: relative;
}

.switch-button:before {
  content: "Updates";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 3%;
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
  color: #EBEBEB;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}

.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(100%);
  transition: transform 400ms linear;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 9px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}

.switch-button-checkbox + .switch-button-label:before {
  content: "";
  background: #151515;
  height: 80%;
  width: 98%;
  position: absolute;
  left: 2%;
  top: 10%;
  border-radius: 30px;
  transform: translateX(0);
  transition: transform 400ms;
}

.switch-button-checkbox + .switch-button-label .switch-button-label-span {
  position: relative;
}

#location-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 15px;
  padding-left: 25px;
  padding-right: 25px;
}

#location-name {
  font-size: 25px;
  font-weight: bold;
  color: #EBEBEB;
  text-align: center;
  white-space: nowrap;
}

#location-subtext {
  font-size: 12px;
  margin-top: -5px;
  color: #858585;
}

#location-header-right {
  font-size: 35px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #EBEBEB;
  margin-left: auto;
}

#location-header-left {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

  
#graph-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#graph-x-index {
  width: 100%;
  display: flex;
  font-size: 12px;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 11%;
  margin-bottom: 12px;
  color: #FDFDFD;
}

#graph-y-index {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-between;
  color: #FDFDFD;
  padding-top: 5px;
}

.display-rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

#ratings-div {
  display: flex;
  justify-content: space-evenly;
  margin-top: 12px;
  padding-bottom: 12px;
  color: #EBEBEB;
}

#people-stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#people-text {
  font-size: 10px;
  color: #858585;
}

#location-card-1 {
  margin: 15px;
  border-radius: 25px;
  background-color: #151515;
}

#range-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin: 0.8rem;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 25px;
  background-color: #151515;
  color: #858585;
  text-align: center;
  line-height: 2;
}

#range-message-container {
  
}

.range-line {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
}

.range-line-container {
  padding-left: 1rem;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.range-message-title {
  font-size: 18px;
  color: #CBCBCB;
  padding-bottom: 0.5rem;
}

.range-subtitle {
  display: flex;
  justify-content: center;
  color: #aaaaaa;
  font-size: 17px;
}

.normal-button {
  background-color: #151515;
  border: 1px solid #494949;
  border-radius: 8px;
  width: 75px;
  height: 32px;
  color: #CBCBCB;
  font-size: 12px;
  padding: 2px 2px;
}

.blue-button {
  background-color: #CBCBCB;
  color: #151515;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  width: 75px;
  height: 32px;
  font-size: 13px;
  padding: 2px 2px;
}

#distance-select {
  -webkit-appearance: none;
  background-color: #151515;
  border: 1px solid #494949;
  border-radius: 8px;
  width: 75px;
  height: 32px;
  color: #CBCBCB;
  font-size: 13px;
  padding-left: 12px;
}

#filter-options {
  display: flex;
  padding-left: 5%;
  gap: 2.5%;
  padding-bottom: 1.5rem;
}

#explore {
  color: #EBEBEB;
  font-size: 30px;
  font-weight: 500;
  padding-left: 5%;
}

#search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 0.8rem;
}
  
#search-input {
  border-radius: 8px;
  background-color: #151515;
  color: #666666;
  width: 90%;
  height: 30px;
  padding-left: 50px;
  font-size: 13px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666666;
  opacity: 1; /* Firefox */
  font-size: 15px;
}

.featured-events-container {
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 0.3rem;
}

#featured-events-subtitle {
  color: #EBEBEB;
  font-size: 20px;
  font-weight: 500;
  
}

#locations-subtitle {
  color: #EBEBEB;
  font-size: 20px;
  font-weight: 500;
  padding-left: 5%;
  padding-bottom: 1rem;
}

.color-bar {
  height: 0.5rem;
  width: 100%;
  background-image: linear-gradient(to right, #a1d1fe, #95cbfe, #88c5ff, #7bbeff, #6db8ff, #60b1ff, #53aaff, #45a3ff, #379aff, #2c90ff, #2586ff, #267cfe);
}

.color-bar-top {
  height: 0.7rem;
  width: 100%;
  background-color: transparent;
}

.main-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

#footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  color: #4d4d4d;
  gap: 1rem;
  font-size: 14px;
}

.report-line {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}

#ig-logo {
  display: flex;
  justify-content: center;
  align-items: center
}

#copyright {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem;
}

#no-results {
  display: flex;
  justify-content: center;
  color: #CBCBCB;
  font-size: 18px;
  padding-bottom: 1rem;
}

#comment-bar-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 95%;
  border-radius: 15px;
}

#comment-bar-outer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw - 30px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 15px;
  margin-bottom: 2px;
  padding-top: 15px;
  border-radius: 15px;
  background: rgba(0, 0, 0, 0.76);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.9px);
  -webkit-backdrop-filter: blur(2.9px);
  border: 1px solid rgba(0, 0, 0, 0.24);
  z-index: 200;
}

#comment-bar {
  display: flex;
  width: 96%;
  height: 30px;
  background-color: #1b1b1b;
  color: #aaaaaa;
  border-radius: 15px;
  padding-left: 2rem;
  font-size: 15px;
  align-items: center;
  caret-color: #1f61c4;
}

#comment-bar:focus {
  outline: none;
  background-color: #090909;
  border: 0.7px solid #4D4C4C;
}

#comment-bar:focus::placeholder {
  color: #4D4C4C;
}

#submit-comment {
  width: 27px;
  height: 27px;
  background-color: #1f61c4;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.svg-container-2 {
  width: 23px;
  height: 23px;
  color: #090909;
}

#submit-comment-grey {
  background-color: #1f61c4;
  color: #CBCBCB;
  opacity: 0.5;
  width: 27px;
  height: 27px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#character-count {
  font-size: 14px;
  color: #858585;
}

#comments {
  color: #EBEBEB;
}

.comment-line {
  border-bottom: 1px solid #2c2c2c;
  padding-top: 10px;
  padding-bottom: 10px;
}

.comment-line-pinned {
  padding-top: 10px;
  padding-bottom: 10px;
}

.comment-line-one {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  font-size: 11px;
}

.comment-line-two {
  display: flex;
  align-items: center;
  padding-left: 4px;
  font-size: 13px;
  gap: 0.25rem;
}

.user-bubble {
  background-color: #979696;
  border-radius: 6.5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  color: #151515;
}

.liked-button {
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.3s ease;
  background-color: #ffa8c2ef;
  pointer-events: auto;
  border: 1px solid #f65c8a;
  margin-left: auto;
}

.liked-button svg path {
  fill: #f65c8a;
}

.liked-button:active {
  transform: scale(0.88);
}

.liked-button:active svg {
  transform: scale(0.6);
}

.unliked-button {
  background-color: #1b1b1b;
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.3s ease;
  margin-left: auto;
  /* Add any additional styling for unliked button */
}

.unliked-button:active {
  transform: scale(0.88);
}

.unliked-button:active svg {
  transform: scale(0.6);
}

.disliked-button {
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.3s ease;
  background-color: #f76b6bee;
  pointer-events: auto;
  border: 1px solid #c51c1c;
}

.disliked-button svg path {
  fill: #c51c1c;
  stroke: #c51c1c;
}

.disliked-button:active {
  transform: scale(0.88);
}

.disliked-button:active svg {
  transform: scale(0.6);
}

.undisliked-button {
  background-color: #1b1b1b;
  position: relative;
  cursor: pointer;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  transition: all 0.3s ease;
  /* Add any additional styling for unliked button */
}

.undisliked-button:active {
  transform: scale(0.88);
}

.undisliked-button:active svg {
  transform: scale(0.6);
}

.svg-container {
  min-width: 15px; /* Adjust the value as needed */
  min-height: 15px; /* Adjust the value as needed */
  display: flex;
  justify-content: center;
  align-items: center;
}

.pinned-comment {
}

.pinned-comments-section {
  padding-left: 15px;
  padding-right: 15px;
  background-color: #1f61c4;
  border-radius: 15px;
  position: sticky;
  top: 0.5rem;
  border: none;
  z-index: 100;
  border: 3px solid rgb(31, 97, 196);
  background: rgba(24, 25, 26, 0.63);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);

}

.preview-comments {
  background-color: #151515;
  border-radius: 20px;
  padding: 15px;
}

.preview-comments:active {
  background-color: #292929;
}

.preview-comments-border {
  padding: 2px;
  background-image: radial-gradient(circle, #a1d1fe, #95cbfe, #88c5ff, #7bbeff, #6db8ff, #60b1ff, #53aaff, #45a3ff, #379aff, #2c90ff, #2586ff, #267cfe);
  margin: 15px;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.preview-comments-border-none {
  padding: 2px;
  margin: 15px;
  background-color: #151515;
  border-radius: 20px;
  margin-bottom: 1rem;
}

.main-comments {
  background-color: #151515;
  padding: 15px;
}

.comment-container {
  margin: 15px;
  margin-bottom: 65px;
  background-color: #151515;
  border-radius: 15px;
  padding-bottom: 1rem;
  min-height: 44vh;
}

.comment-timestamp {
  font-size: 12px;
  color: #858585;
}

.comment-text {
  padding-right: 5px;
}

.wave-wall {
  font-size: 23px;
}

#comment-header {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  gap: 1rem;
}

.close-button {
  display: flex;
  background-color: #292929;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #141414;
  padding: 3px;
  margin-left: auto;
}

.close-button:active {
  background-color: #333;
}

#no-comments {
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  color: #858585;
  font-size: 15px;
}

.popup-content {
  position: absolute;
  top: 52vh;
  z-index: 400;
  width: 100vw;
  animation: slideIn 0.3s linear;
}

@keyframes slideIn {
  0% {
    transform: translateY(400px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

.wave-logo {
  width: 40px;
  height: 40px;
  border-radius: 10px;
}

.noti-container {
  display: flex;
  align-items: center;
  color: #EBEBEB;
  height: 75px;
  border-radius: 15px;
  gap: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw - 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  padding-left: 1rem;
  z-index: 500;
  font-size: 13px;
  background: rgba(46, 45, 45, 0.66);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid rgba(46, 45, 45, 0.24);
  animation: slideDown 0.5s linear;
}

.noti-container-location {
  display: flex;
  align-items: center;
  color: #151515;
  height: 75px;
  border-radius: 15px;
  gap: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw - 30px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  padding-left: 1rem;
  z-index: 500;
  font-size: 13px;
  background-color:#cbc94d;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7.1px);
  -webkit-backdrop-filter: blur(7.1px);
  border: 1px solid rgba(46, 45, 45, 0.24);
  animation: slideDown 0.5s linear;
}

@keyframes slideDown {
  0% {
    transform: translateY(-200px);
    animation-timing-function: ease-out;
  }
  100% {
    transform: translateY(0px);
    animation-timing-function: ease-in;
  }
}

.noti-inner-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1rem;
}

.noti-line-one {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 100%;
}

.noti-user {
  font-size: 14px;
  font-weight: 400;
}

.wave-team {
  font-size: 14px;
  font-weight: 600;
}

.noti-timestamp {
  color: #979696;
}

#mute-button {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
  height: 35px;
  width: 35px;
  margin-left: auto;
}

#mute-button:active {
  background-color: #2c2c2c;
}

#ig-button {
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b1b1b;
  height: 35px;
  width: 35px;
}

#ig-button:active {
  background-color: #2c2c2c;
}

.bell-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
}

#sort-select {
  -webkit-appearance: none;
  background-color: #292929;
  border-radius: 10px;
  width: 85px;
  height: 30px;
  color: #aaaaaa;
  font-size: 13px;
  padding-left: 12px;
}

#sort-select:focus {
  outline: none;
}

#go-home {
  display: inline;
  background-color: transparent;
  border-bottom: 1px solid #6db8ff;
  color: #858585;
  cursor: pointer;
  font-size: inherit;
  text-align: center;
  padding: 0;
}

#precise-location {
  display: inline;
  border-bottom: 1px solid #6db8ff;
  color: #858585;
  text-decoration: none;
  cursor: pointer;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  text-align: center;
}

#allow-location-container {
  display: block;
}

#allow-location {
  display: inline;
  background-color: #151515;
  border: 1px solid #494949;
  color: #CBCBCB;
  text-decoration: none;
  padding: 0.2rem 0.5rem;
  cursor: pointer;
  margin-right: 0.25rem;
  border-radius: 5px;
  font-size: inherit;
  text-align: center;
}

.scrolling-wrapper {
  -webkit-overflow-scrolling: touch;
  padding: 0 5%;
  padding-bottom: 1.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.scrolling-wrapper::-webkit-scrollbar {
  display: none;
}

.event-card-container {
  background-color: #151515;
  display: flex;
  gap: 1rem;
  padding: 10px 10px;
  padding-bottom: 1px;
  padding-top: 5px;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 115px;
  margin: 2px;
  margin-bottom: -0.5px;
}

.event-button-link {
  display: flex;
  background-color: #151515;
  text-decoration: none;
  color: #333;
  font-weight: bold;
  cursor: pointer;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 100%;
  margin-bottom: -0.5px;
}

.card {
  display: inline-block;
  width: 45%;
  margin-right: 1rem;
}

.last-item {
  margin-right: 5%;
}

.event-card-inner {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.event-name {
  font-size: 15px;
  font-weight: 400;
  color: #aaaaaa;
  overflow: hidden;
  max-width: 95%; /* Allow the text to fill the available width within the box */
  white-space: pre-wrap;
}

.event-time {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 10px;
  font-weight: 400;
  color: #979696;
  stroke: #979696;
  height: 20px;
  margin-bottom: 1rem;
}

.buy {
  -webkit-appearance: none;
  background-color: #292929;
  border-radius: 7px;
  height: 27px;
  width: 55px;
  color: #aaaaaa;
  font-size: 11px;
  padding: 0;
  display: flex;
  align-items: center;
  padding-left: 4px;
  text-decoration: none;
}

.buy:focus {
  outline: none;
}

.buy:active {
  background-color: #333;
}

.buy-box {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color: #25ff5b73;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7bff82;
}

.price-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.going {
  -webkit-appearance: none;
  background-color: #292929;
  border-radius: 7px;
  width: 70px;
  height: 27px;
  color: #aaaaaa;
  font-size: 11px;
  padding: 0;
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.going:focus {
  outline: none;
}

.going:active {
  background-color: #333;
}

.going-box {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color: #2587ff73;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7bbeff;
  font-weight: 600;
}

.going-box.on {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color: #2587ff73;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7bbeff;
  font-weight: 600;
}

.going-box.off {
  background-color: #494949;
  color: #858585;
}

.going-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.event-buttons-background{
  display: flex;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background-color: #151515;
}

.event-buttons {
  margin-top: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 50px;
  background-color: #151515;
  align-items: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  margin: 2px;
  width: 100%;
  margin-top: -0.5px;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color:#666666;
}

.bar-name-small-line {
  display: flex;
  gap: 10px;
  display: flex;
  align-items: center;
  width: 95%;
  overflow: hidden;
}

.filter-buttons-container {
  display: flex;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  gap: 0.5rem;
  padding-bottom: 1rem;
}

.filter-button {
  padding: 8px 6px;
  font-size: 14px;
  background-color: #090909;
  color: #6B6B6B;
}

.filter-button.selected {
  color: #CBCBCB;
  font-weight: 500;
  border-bottom: 2px solid #CBCBCB;
}

.event-banner {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: 500;
}

#no-events {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  height: 153px;
}

#logo-container {
  display: flex;
  flex-direction: column;
}


#mini-logo {
  display: flex;
  justify-content: flex-end;
  gap: 3px;
  margin-top: -3px;
  margin-right: 3px;
}

#mini-logo-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 13px;
  height: 13px;
  border-radius: 1px;
  background-color: #383838;
  color: #aaaaaa;
  font-size: 10px;
  font-weight: 400;
}

#story-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 450;
}

.image-container {
  position: relative;
  width: 100vw;
  height: 100dvh;
  background-color: #090909;
}

.story-photo {
  width: 100vw;
  height: 82.5dvh;
  border-radius: 20px;
  position: absolute; 
  left: 50%;
  transform: translate(-50%, 0);
  margin-top: 0.3rem;
}


.story-timestamp {
  position: absolute;
  width: 250px;
  height: 35px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 130px;
  background-color: #090909;
  border-radius: 20px;
  color: #aaaaaa;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  z-index: 500;
  font-size: 14px;
}

.story-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: absolute;
  left: 0;
  bottom: 0;
  padding-right: 11%;
  background-color: #090909;
  width: 100%;
  padding-bottom: 1rem;
  padding-left: 11vw;
  padding-top: 1.5rem;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.story-title {
  color: #CBCBCB;
  font-size: 22px;
  font-weight: 600;
}

.story-desciption {
  color: #aaaaaa;
  font-size: 15px;
  height: 40px;
  overflow: wrap;
}

.prev-button,
.next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0);
  width: 20vw;
  border: none;
  cursor: pointer;
  height: 100%;
}

.prev-button {
  left: 0;
  width: 10vw;
}

.next-button {
  right: 0;
  width: 50vw;
}

.story-preview {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 2px solid #d6d455;
}

.story-small {
  width: 50px;
  height: 50px;
  transition: all 0.2s ease-in-out;
}

.story-small:active {
  transform: scale(0.85);
}

.event-buttons-story{
  display: flex;
  gap: 10px;
}

.music-type-container {
  -webkit-overflow-scrolling: touch;
  padding: 0 15px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
}

.music-type-container::-webkit-scrollbar {
  display: none;
}

.music-type {
  flex: 0 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #151515;
  border-radius: 8px;
  height: 30px;
  padding: 0 10px;
  color: #aaaaaa;
  font-size: 13px;
}

.genre-banner {
  font-size: 13px;
  color: #aaaaaa;
  font-weight: 500;
}

.card-outer {
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  height: 100px;
  margin: 2px;
  color: white;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.card-outer:active {
  background-color: #292929;
}

.grid-container {
  padding: 0 16px;
  margin-top: 3px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Creates three equal-width columns */
  gap: 10px; /* Optional: Add gap between grid items */
}

.grid-item-one {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.grid-item-three {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.grid-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.grid-item-inner {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.music-box {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #7bff82;
}

.date-box {
  width: 20px;
  height: 20px;
  min-width: 20px;
  border-radius: 5px;
  background-color:#151515;
  border: 1px solid #666666;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #151515;
}

.all-events {
  margin-left: auto;
  text-decoration: none;
  color: #aaaaaa;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.calendar {
  -webkit-overflow-scrolling: touch;
  padding: 0 5%;
  padding-bottom: 1.5rem;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
}

.calendar::-webkit-scrollbar {
  display: none;
}

.day {
  display: inline-block;
  width: 15%;
  margin-right: 0.5rem;
  height: 80px;
  background-color: #151515;
  border-radius: 12px;
}

.day-of-month {
  font-size: 25px;
  color: #CBCBCB;
  margin-bottom: 0.5rem;
}

.week-day {
  font-size: 12px;
  color: #858585;
}

.date {
  color: #CBCBCB;
}

.events-title {
  color: #EBEBEB;
  font-size: 30px;
  font-weight: 500;
  padding-left: 5%;
  padding-top: 2rem;
}

.event-grid {
  padding: 2rem 3%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  place-items: center;
}

.card-two {
  display: flex;
  flex-direction: column;
  width: 85%;
}

iframe {
  position: absolute;
  top: -70px;
  z-index: -5;
}

.browser-popup {
  /* Common styles for both PWA and browser popups */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #858585;
  font-size: 16px;
}

.browser-container {
  background-color: #151515;
  padding: 2rem 2rem;
  border-radius: 25px;
  padding-top: 0px;
  padding-bottom: 3rem;
}

.instructions {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.i-title {
  font-size: 20px;
  color: #CBCBCB;
  padding-bottom: 10px;
}

.i-line {
  display: flex;
  align-items: center;
  gap: 5px;
}

strong {
  color: #aaaaaa;
}

.pop-up-logo {
  width: 150px;
  margin-bottom: -30px;
}

.back-button {
  display: flex;
  background-color: #292929;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  color: #141414;
  padding: 1px;
  margin-left: -10px;
  margin-top: -10px;
}

.why {
  color: #858585;
  background-color: #151515;
  font-size: 14px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  margin-top: 2em;
  padding: 1.5rem 2rem;
  border-radius: 25px;
}

.why-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}